import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/about/Content";
import CTA from "../sections/nightshift/CTA";

const About = () => {
  return (
    <>
    <Helmet>
      <title>NightShift | About</title>
    </Helmet>
      <PageWrapper>
        <Hero title="Challenge us."></Hero>
        <Content />
      </PageWrapper>
      <CTA />
    </>
  );
};
export default About;
